@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.activeHeader {
  color: #fff !important;
}

.activeSidebar {
  @apply !text-orange-600;
}

.activeSidebar svg {
  @apply !text-orange-700;
}

.activeSidebar .iconBox {
  @apply !bg-orange-100;
}

.rotateAnimation {
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  animation: rotate 1.7s ease-in-out infinite;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  image-rendering: -webkit-optimize-contrast;
}

@keyframes rotate {
  0%,
  100% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(810deg);
  }
}

@keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.rt-row:hover .expand-col {
  visibility: visible !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
